<template >
  <!-- v-if="$vs.rtl" -->
  <div class="w-full">
    <b-row>
      <b-col cols="12" md="2"></b-col>
      <b-col cols="12" md="4">
        <b-card
          :img-src="
            require('@/assets/images/patientHome/RepeatMedicalOnlineConsultations.png')
          "
          img-alt="Card image cap"
          img-top
          no-body
        >
          <p style="text-align: center; color: black">
            {{ $t("SpecialtiesHome") }} 
          </p>
          <b-button
          @click="getSpecialties()"
            variant="primary"
            style="width: 100%"
          >
            {{ $t("Specialties") }}
          </b-button>
        </b-card>
      </b-col>
      <b-col cols="12" md="4">
        <b-card
          :img-src="
            require('@/assets/images/patientHome/YourMedicalReports.png')
          "
          img-alt="Card image cap"
          img-top
          no-body
        >
          <p style="text-align: center; color: black">
            {{ $t("DoctorsddddddHome") }}
          </p>
          <b-button
          @click="getDoctors()"
            variant="primary"
            style="width: 100%"
          >
            {{ $t("Doctors") }}
          </b-button>
        </b-card>
      </b-col>
      <b-col cols="12" md="2"></b-col>
      <b-col cols="12" md="2"></b-col>

<b-col cols="12 " md="8">
  <b-row>
    <b-col cols="12" md="2"></b-col>
        <b-col  cols="12 " md="4">
          <b-button
            @click="AddSpecialties()"
            variant="primary"
            style="width: 100%"
          >
            {{ $t("AddSpecialties") }}
          </b-button>
        </b-col>
          <b-col  cols="12 " md="4">
           <b-button
            @click="AddDoctors()"
            variant="primary"
            style="width: 100%"
          >
            {{ $t("AddDoctors") }}
          </b-button>
        </b-col>
      </b-row>

      <b-row class="mt-2">
        <b-col cols="12" md="2"></b-col>
        <b-col cols="12 " md="4"> 
          <b-button
            @click="SurgeriesTreatments()"
            variant="primary"
            style="width: 100%"
          >
            {{ $t("SurgeriesTreatments") }}
          </b-button></b-col>
          <b-col cols="12 " md="4">  <b-button
          @click="ManagePayments()"
            variant="primary"
            style="width: 100%"
          >
            {{ $t("PaymentStatus") }}
          </b-button></b-col>
      </b-row>
</b-col>
     
  
        </b-row>
    <!-- <vs-popup fullscreen title="" :active.sync="showReservedPayments">
        <payment  
        v-if="showReservedPayments"
         @closePop="changePaymentStatus" />
    </vs-popup> -->

     
  </div>
</template>

<script>
import HospitalModule from "@/store/hospital/moduleHospital.js";
import payment from "@/views/hospital/payments.vue";
import SurgerySetPopup from "./SurgerySetPopup.vue"
import modulePaymentStatus from "@/store/settings/paymentStatus/modulePaymentStatus.js";
import {
BButton,
BCard,
BCardBody,
BCardFooter,
BCardHeader,
BCardText,
BCol,
BImg,
BLink,
BRow
} from "bootstrap-vue";
export default {
  components: {payment,SurgerySetPopup,BButton,
BCard,
BCardBody,
BCardFooter,
BCardHeader,
BCardText,
BCol,
BImg,
BLink,
BRow},
  data() {
    return {
      ShowChangeStatus:false,
addItemModel:{},
      showReservedPayments:false,
      propBG1: {
        backgroundImage: `url(${require("@/assets/images/hospital/card1.png")}) `,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
      },
      propBG2: {
        backgroundImage: `url(${require("@/assets/images/hospital/card2.png")})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
      },
      arrowIcon: {
        backgroundImage: `url(${require("@/assets/images/hospital/arrowIcon.svg")})`,
        backgroundRepeat: "no-repeat",
      },
    };
  },

  methods: {
    getSpecialties() {
       this.$router.push("/hospital/Specialty");
    },
    getDoctors() {
       this.$router.push("/hospital/Doctor");
    },
    AddSpecialties() {
      this.$router.push({ name: "AddHospitalSpecialites" });
    },
    SurgeriesTreatments() {
      this.$router.push({ name:"ReservedSurgery" });
    },
    AddDoctors() {
      this.$router.push({ name: "AddHospitalDoctors" });
    },
    
    ManagePayments() {
       //this.showReservedPayments=true;
       this.$router.push({ name: "HospitalPayments" });
    },
     
  },
  created() {
    if (!HospitalModule.isRegistered) {
      // this.$vs.loading();
      this.$store.registerModule("HospitalList", HospitalModule);
      HospitalModule.isRegistered = true;
    }
      if (!modulePaymentStatus.isRegistered) {
      this.$store.registerModule(
        "PaymentStatusList",
        modulePaymentStatus
      );
      modulePaymentStatus.isRegistered = true;
    }

    this.$store.dispatch("PaymentStatusList/GetAllPaymentStatus");
    
  },
  mounted() {
    this.isMounted = true;
  },
};
</script>

<style scoped>
#hospitalHome .backgroundShadow {
  background-color: transparent;
  box-shadow: rgb(0 0 0 / 20%) 5px 5px 15px;
  box-shadow: rgb(0 0 0 / 0%) 5px 5px 15px;
}

#hospitalHome .vs-button {
  margin-bottom: 20px !important;
}
#hospitalHome .text-sm {
  font-size: 0.95rem !important;
  font-weight: 600;
}
#hospitalHome h3 {
  font-weight: bold;
  font-size: 2rem !important;
}
</style>
